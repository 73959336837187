import request from '../utils/request'

export const indicators=()=>{
    return request({
        url:'/admin/indicators',
        method:'get',
    })
}
export const overall=(company,branch_content,level)=>{
    return request({
        url:'/admin/overall',
        method:'post',
        data:{
            company,
            branch_content,
            level
        }
    })
}
export const hour_index=(company,branch_content,level)=>{
    return request({
        url:'/admin/hour_index',
        method:'post',
        data:{
            company,
            branch_content,
            level
        }
    })
}
export const all_hour_mean=(company,branch_content,level)=>{
    return request({
        url:'/admin/all_hour_mean',
        method:'post',
        data:{
            company,
            branch_content,
            level
        }
    })
}
export const hour_warning=(company,branch_content,level)=>{
    return request({
        url:'/admin/hour_warning',
        method:'post',
        data:{
            company,
            branch_content,
            level
        }
    })
}
