<template>
	<div class="container">
		<div class="header">
			<div class="time">{{dateTime}}</div>
			<div class="page-title">
				<div class="header-text">{{company}}人员情绪识别数据分析系统</div>
				<div class="title-bg"></div>
			</div>
			<div class="header-r">
				<div class="online">当前在线设备数:{{onLine}}台</div>
				<div class="overview" @click="bigdata">进入概览</div>
			</div>
		</div>
		<div class="header-b"></div>
		<div class="main">
			<div class="main-left">
				<div class="emotion-warning bor" v-bind:class="{borderBox:emotion_wram[0].length>0}">
					<div class="module-title">
						<div class="title-icn" v-bind:class="{warmbgcolor:emotion_wram[0].length>0}"></div>
						<div class="title-text" v-bind:class="{borderBox:emotion_wram[0].length>0}">情绪稳定性预警</div>
					</div>
					<div class="modal-a">

					</div>
					<div class="modal-b" v-bind:class="{boderLF:emotion_wram[0].length>0}">
						<el-carousel height="22vh" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="(user,i) in emotion_wram" :key="i">
								<div class="left-bolck-content" style="color: white;">
									<table style="width: 100%;text-align: center;">
										<tr class="tr-title">
											<td v-bind:class="{borderBox:emotion_wram[0].length>0}">姓名</td>
											<td v-bind:class="{borderBox:emotion_wram[0].length>0}">所在部门</td>
											<td v-bind:class="{borderBox:emotion_wram[0].length>0}">专业</td>
											<td v-bind:class="{borderBox:emotion_wram[0].length>0}">年龄</td>
											<td v-bind:class="{borderBox:emotion_wram[0].length>0}">情绪稳定性</td>
										</tr>
										<tr class="tr-body" v-for="(item,index) in user" v-bind:key="index">
											<td>{{item.nickname}}</td>
											<td>{{item.branch_1}}</td>
											<td>{{item.joy}}</td>

											<td v-if="item.birthday=='null'||item.birthday==null"></td>
											<td v-else>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<td>{{item.emotion_mean}}<span class="statusDot"
													v-bind:class="{dangerDot:item.emotion_mean<=49,warmDot:item.emotion_mean<69}"></span>
											</td>
										</tr>
									</table>
								</div>
							</el-carousel-item>
							<div style="width: 100%;height: 26vh;line-height: 26vh;color: #007297;text-align: center;font-size: 0.22rem;"
								v-if="emotion_wram[0].length<1">
								暂无预警
							</div>
						</el-carousel>
					</div>
				</div>
				<div class="tired-warning bor" v-bind:class="{borderBox:tired_wram[0].length>0}">
					<div class="module-title">
						<div class="title-icn" v-bind:class="{warmbgcolor:tired_wram[0].length>0}"></div>
						<div class="title-text" v-bind:class="{borderBox:tired_wram[0].length>0}">疲劳预警</div>
					</div>
					<div class="modal-a" v-bind:class="{borderTB:tired_wram[0].length>0}"></div>
					<div class="modal-b" v-bind:class="{boderLF:tired_wram[0].length>0}">
						<el-carousel height="22vh" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="(user,i) in tired_wram" :key="i">
								<div class="left-bolck-content" style="color: white;">
									<table style="width: 100%;text-align: center;">
										<tr class="tr-title">
											<td v-bind:class="{borderBox:tired_wram[0].length>0}">姓名</td>
											<td v-bind:class="{borderBox:tired_wram[0].length>0}">所在部门</td>
											<td v-bind:class="{borderBox:tired_wram[0].length>0}">专业</td>
											<td v-bind:class="{borderBox:tired_wram[0].length>0}">年龄</td>
											<td v-bind:class="{borderBox:tired_wram[0].length>0}">疲劳指数</td>
										</tr>
										<tr class="tr-body" v-for="(item,index) in user" v-bind:key="index">
											<td>{{item.nickname}}</td>
											<td>{{item.branch_1}}</td>
											<td>{{item.joy}}</td>
											<td v-if="item.birthday=='null'||item.birthday==null"></td>
											<td v-else>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<!-- <td>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td> -->
											<td>{{item.tired_mean}}<span class="statusDot"
													v-bind:class="{dangerDot:item.tired_mean>69,warmDot:item.tired_mean>49}"></span>
											</td>
										</tr>
									</table>
								</div>
							</el-carousel-item>
							<div style="width: 100%;height: 26vh;line-height: 26vh;color: #007297;text-align: center;font-size: 0.22rem;"
								v-if="tired_wram[0].length<1">
								暂无预警
							</div>
						</el-carousel>

					</div>
				</div>
				<div class="stress-warning bor" v-bind:class="{borderBox:stress_wram[0].length>0}">
					<div class="module-title">
						<div class="title-icn" v-bind:class="{warmbgcolor:stress_wram[0].length>0}"></div>
						<div class="title-text" v-bind:class="{borderBox:stress_wram[0].length>0}">压力预警</div>
					</div>
					<div class="modal-a" v-bind:class="{borderTB:stress_wram[0].length>0}"></div>
					<div class="modal-b" v-bind:class="{boderLF:stress_wram[0].length>0}">
						<el-carousel height="22vh" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="(user,i) in stress_wram" :key="i">
								<div class="left-bolck-content" style="color: white;">
									<table style="width: 100%;text-align: center;">
										<tr class="tr-title">
											<td v-bind:class="{borderBox:stress_wram[0].length>0}">姓名</td>
											<td v-bind:class="{borderBox:stress_wram[0].length>0}">所在部门</td>
											<td v-bind:class="{borderBox:stress_wram[0].length>0}">专业</td>
											<td v-bind:class="{borderBox:stress_wram[0].length>0}">年龄</td>
											<td v-bind:class="{borderBox:stress_wram[0].length>0}">压力指数</td>
										</tr>
										<tr class="tr-body" v-for="(item,index) in user" v-bind:key="index">
											<td>{{item.nickname}}</td>
											<td>{{item.branch_1}}</td>
											<td>{{item.joy}}</td>
											<td v-if="item.birthday=='null'||item.birthday==null"></td>
											<td v-else>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<!-- <td>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td> -->
											<td>{{item.stress_mean}}<span class="statusDot"
													v-bind:class="{dangerDot:item.stress_mean>69,warmDot:item.stress_mean>49}"></span>
											</td>
										</tr>
									</table>
								</div>
							</el-carousel-item>
							<div style="width: 100%;height: 26vh;line-height: 26vh;color: #007297;text-align: center;font-size: 0.22rem;"
								v-if="stress_wram[0].length<1">
								暂无预警
							</div>
						</el-carousel>
					</div>
				</div>
			</div>
			<div class="main-mid">
				<el-amap class="bm-view" vid="aafd" :zoom="10" :center="infoLocation">
					<el-amap-marker :offset="[-24,-48]" :icon="baseUrl+'/public/images/point.png'"
						v-for="(marker,index) in user_point" :position="[marker.Lon, marker.Lat]" :key="index"
						:vid="index" :events="marker.eventClick">
					</el-amap-marker>
					<el-amap-info-window :position="infoLocation" :visible="showInfo" :events="infoEvent">
						<div style="width: 285px;height: 90px;font-size: 16px;">
							<span
								style="font-size: 20px;font-weight: 600;margin-right: 15px;">{{ userInfo.nickname}}</span>
							<span>{{ userInfo.upload_time || '' }}</span>
							<p style="font-size: 16px;line-height: .1rem;">
								{{userInfo.company || ''}},{{userInfo.gender || ''}} 
								{{(new Date().getFullYear() - (userInfo.birthday || '').split('-')[0]+1) || '' }}{{userInfo.branch_2 || ''}}
								{{userInfo.barnch_1 || ''}}
							</p>
							<p style="font-size: 16px;line-height: .1rem;">
								情绪：{{userInfo.emotion_mean}}
								<span class="statusDot"
									v-bind:class="{dangerDot:userInfo.emotion_mean<50,warmDot:userInfo.emotion_mean<70}"
									style="">
								</span>
								疲劳：{{userInfo.tired_mean}}
								<span class="statusDot"
									v-bind:class="{dangerDot:userInfo.tired_mean>69,warmDot:userInfo.tired_mean>49}"
									style="">
								</span>
								压力：{{userInfo.stress_mean}}
								<span class="statusDot"
									v-bind:class="{dangerDot:userInfo.stress_mean>69,warmDot:userInfo.stress_mean>49}"
									style="">
								</span>
							</p>
						</div>
					</el-amap-info-window>
				</el-amap>
				<div class="index">
					<div class="index-item bor" v-bind:class="{borderBox:emotionIcn==1}">
						<div class="module-title">
							<div class="title-icn" v-bind:class="{warmbgcolor:emotionIcn==1}"></div>
							<div class="title-text" v-bind:class="{borderBox:emotionIcn==1}">今日总体-情绪稳定性</div>
						</div>
						<div class="modal-a" v-bind:class="{borderTB:emotionIcn==1}">
						</div>
						<div class="modal-b main-center" v-bind:class="{boderLF:emotionIcn==1}">
							<div class="box1">
								<span class="left-t">均值</span>
								<span class="left-num">{{allEmotion}}</span>
<!-- 								<img :src="baseUrl+'/public/static/images/emotion1.png'" alt="" class="index-pic"
									v-if="emotionIcn==3">
								<img :src="baseUrl+'/public/static/images/emotion2.png'" alt="" class="index-pic"
									v-if="emotionIcn==2">
								<img :src="baseUrl+'/public/static/images/emotion3.png'" alt="" class="index-pic"
									v-if="emotionIcn==1"> -->
							</div>
							<div class="box1" v-if="allEmotion!=-1&&allEmotionPre!=-1">
								<span class="rise-t">同比昨日</span>
								<img v-if="allEmotion>allEmotionPre" class="rise-icon" :src="riseUp" />
								<img v-if="allEmotion<=allEmotionPre" class="rise-icon" :src="riseDown" />
								<span class="rise-num">{{contrastEmotion}}%</span>
							</div>
						</div>
					</div>
					<div class="index-item bor" v-bind:class="{borderBox:tiredIcn==3}">
						<div class="module-title">
							<div class="title-icn" v-bind:class="{warmbgcolor:tiredIcn==3}"></div>
							<div class="title-text" v-bind:class="{borderBox:tiredIcn==3}">今日总体-疲劳指数</div>
						</div>
						<div class="modal-a" v-bind:class="{borderTB:tiredIcn==3}">
						</div>
						<div class="modal-b main-center" v-bind:class="{boderLF:tiredIcn==3}">
							<div class="box1">
								<span class="left-t">均值</span>
								<span class="left-num">{{allTired}}</span>
<!-- 								<img class="index-pic" :src="baseUrl+'/public/static/images/tired1.png'" alt=""
									v-if="tiredIcn==1">
								<img class="index-pic" :src="baseUrl+'/public/static/images/tired2.png'" alt=""
									v-if="tiredIcn==2">
								<img class="index-pic" :src="baseUrl+'/public/static/images/tired3.png'" alt=""
									v-if="tiredIcn==3"> -->
							</div>
							<div class="box1" v-if="allTired!=-1&&allTiredPre!=-1">
								<span class="rise-t">同比昨日</span>
								<img v-if="allTired>allTiredPre" class="rise-icon" :src="riseUp" />
								<img v-if="allTired<=allTiredPre" class="rise-icon" :src="riseDown" />
								<span class="rise-num">{{contrastTired}}%</span>
							</div>
						</div>
					</div>
					<div class="index-item bor" v-bind:class="{borderBox:stressIcn==3}">
						<div class="module-title">
							<div class="title-icn" v-bind:class="{warmbgcolor:stressIcn==3}"></div>
							<div class="title-text" v-bind:class="{borderBox:stressIcn==3}">今日总体-压力指数</div>
						</div>
						<div class="modal-a" v-bind:class="{borderTB:stressIcn==3}">
						</div>
						<div class="modal-b main-center" v-bind:class="{boderLF:stressIcn==3}">
							<div class="box1">
								<span class="left-t">均值</span>
								<span class="left-num">{{allStress}}</span>
<!-- 								<img :src="baseUrl+'/public/static/images/stress1.png'" alt="" class="index-pic"
									v-if="stressIcn==1">
								<img :src="baseUrl+'/public/static/images/stress2.png'" alt="" class="index-pic"
									v-if="stressIcn==2">
								<img :src="baseUrl+'/public/static/images/stress3.png'" alt="" class="index-pic"
									v-if="stressIcn==3"> -->
							</div>
							<div class="box1" v-if="allStress!=-1&&allStressPre!=-1">
								<span class="rise-t">同比昨日</span>
								<img v-if="allTired>allTiredPre" class="rise-icon" :src="riseUp" />
								<img v-if="allTired<=allTiredPre" class="rise-icon" :src="riseDown" />
								<span class="rise-num">{{contrastStress}}%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="tired-trend bor">
					<div class="module-title">
						<div class="title-icn"></div>
						<div class="title-text">今日{{echartTitle}}趋势</div>
					</div>
					<div class="modal-a"></div>
					<div class="modal-b" id="main" style="margin-top: 12px;"></div>
				</div>
			</div>
			<div class="main-right">
				<div class="emotion-overview bor">
					<div class="module-title">
						<div class="title-icn"></div>
						<div class="title-text">情绪稳定性-概览</div>
					</div>
					<div class="modal-a"></div>
					<div class="modal-b">
						<el-carousel height="22vh" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="(user,i) in userData" :key="i">
								<div class="left-bolck-content" style="color: white;">
									<table style="width: 100%;text-align: center;">
										<tr class="tr-title">
											<td>姓名</td>
											<td>所在部门</td>
											<td>专业</td>
											<td>年龄</td>
											<td>情绪稳定性</td>
										</tr>
										<tr class="tr-body" v-for="(item,index) in user" v-bind:key="index">
											<td>{{item.nickname}}</td>
											<td>{{item.branch_1}}</td>
											<td>{{item.joy}}</td>
											<td v-if="item.birthday=='null'||item.birthday==null"></td>
											<td v-else>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<!-- <td>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td> -->
											<td>{{item.emotion_mean}}<span class="statusDot"
													v-bind:class="{dangerDot:item.emotion_mean<50,warmDot:item.emotion_mean<70}"></span>
											</td>
										</tr>
									</table>
								</div>
							</el-carousel-item>
							<div style="width: 100%;height: 26vh;line-height: 26vh;color: #007297;text-align: center;font-size: 0.22rem;"
								v-if="userData[0].length<1">
								暂无数据
							</div>
						</el-carousel>
					</div>
				</div>
				<div class="tired-overview bor">
					<div class="module-title">
						<div class="title-icn"></div>
						<div class="title-text">疲劳指数-概览</div>
					</div>
					<div class="modal-a"></div>
					<div class="modal-b">
						<el-carousel height="22vh" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="(user,i) in userData" :key="i">
								<div class="left-bolck-content" style="color: white;">
									<table style="width: 100%;text-align: center;">
										<tr class="tr-title">
											<td>姓名</td>
											<td>所在部门</td>
											<td>专业</td>
											<td>年龄</td>
											<td>疲劳稳定性</td>
										</tr>
										<tr class="tr-body" v-for="(item,index) in user" v-bind:key="index">
											<td>{{item.nickname}}</td>
											<td>{{item.branch_1}}</td>
											<td>{{item.joy}}</td>
											<td v-if="item.birthday=='null'||item.birthday==null"></td>
											<td v-else>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<!-- <td>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td> -->
											<td>{{item.tired_mean}}<span class="statusDot"
													v-bind:class="{dangerDot:item.tired_mean>69,warmDot:item.tired_mean>49}"></span>
											</td>
										</tr>
									</table>
								</div>
							</el-carousel-item>
							<div style="width: 100%;height: 26vh;line-height: 26vh;color: #007297;text-align: center;font-size: 0.22rem;"
								v-if="userData[0].length<1">
								暂无数据
							</div>
						</el-carousel>
					</div>
				</div>
				<div class="stress-overview bor">
					<div class="module-title">
						<div class="title-icn"></div>
						<div class="title-text">压力指数-概览</div>
					</div>
					<div class="modal-a"></div>
					<div class="modal-b">
						<el-carousel height="22vh" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="(user,i) in userData" :key="i">
								<div class="left-bolck-content" style="color: white;">
									<table style="width: 100%;text-align: center;">
										<tr class="tr-title" border="1">
											<td>姓名</td>
											<td>所在部门</td>
											<!-- <td>专业</td> -->
											<td>年龄</td>
											<td>压力指数</td>
										</tr>
										<tr class="tr-body" v-for="(item,index) in user" v-bind:key="index">
											<td>{{item.nickname}}</td>
											<td>{{item.branch_1}}</td>
											<!-- <td>{{item.joy}}</td> -->
											<!-- 											<td v-if="item.birthday">{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<td v-else></td> -->
											<td v-if="item.birthday=='null'||item.birthday==null"></td>
											<td v-else>{{new Date().getFullYear() - item.birthday.split('-')[0]+1}}</td>
											<td>{{item.stress_mean}}<span class="statusDot"
													v-bind:class="{dangerDot:item.stress_mean>69,warmDot:item.stress_mean>49}"></span>
											</td>
										</tr>
									</table>
								</div>
							</el-carousel-item>
							<div style="width: 100%;height: 26vh;line-height: 26vh;color: #007297;text-align: center;font-size: 0.22rem;"
								v-if="userData[0].length<1">
								暂无数据
							</div>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
		<video id="warmPalyer" style="display: none;"
			src="https://downsc.chinaz.net/Files/DownLoad/sound1/202208/y1114.mp3"></video>
	</div>
</template>
<script>
	import {
		// indicators,
		overall,
		hour_index,
		all_hour_mean,
		// hour_warning
	} from '../api/index'
	import * as echarts from "echarts"
	import baseUrl from "../utils/baseUrl.js"
	import {
		getUser,
		getOverall
	} from "../api/visualiztion.js"
	import {
		echartsOption
	} from '../utils/util.js'
	import {
		equipment_list
	} from '../api/equipment'
	var emoImgs = ['/public/images/smile.png', '/public/images/sad.png']
	var riseImgs = ['/public/images/increase.png', '/public/images/reduction.png']
	export default {
		data() {
			return {
				echartTitle: '',
				riseUp: baseUrl + '/public/images/increase.png',
				riseDown: baseUrl + '/public/images/reduction.png',
				hourAll: [],
				allNum: 0,
				allEmotion: -1,
				allStress: -1,
				allTired: -1,
				allEmotionPre: -1,
				allStressPre: -1,
				allTiredPre: -1,
				contrastEmotion: 0,
				contrastStress: 0,
				contrastTired: 0,
				emotionColor: '',
				tiredColor: '',
				stressColor: '',
				emotionIcn: 0,
				tiredIcn: 0,
				stressIcn: 0,
				company: '',
				branch_content: '',
				branch_level: '',

				onLine: 0,
				userInfo: {
					location: {}
				},
				showInfo: false,
				infoLocation: [114.064552, 22.588456],
				baseUrl,
				page_count: 7,
				user_point: [],
				emotion_wram: [
					[]
				],
				emotion_wram_page: 0,
				tired_wram: [
					[]
				],
				tired_wram_page: 0,
				stress_wram: [
					[]
				],
				stress_wram_page: 0,
				emotion_riseImg: baseUrl + riseImgs[0],
				emotion_mean: 0,
				emotion_img: baseUrl + emoImgs[0],

				tired_riseImg: baseUrl + riseImgs[0],
				tired_mean: 0,
				tired_img: baseUrl + emoImgs[0],
				stress_riseImg: baseUrl + riseImgs[0],
				stress_mean: 0,
				stress_img: baseUrl + emoImgs[0],

				dateTime: '2022年1月1日  00:00:00',
				userData: [
					[]
				],
				userData_page: 0,
				infoEvent: {
					close: (e) => {
						this.showInfo = false;
					}
				}
			};
		},
		computed: {},
		methods: {
			//趋势数据
			allHourMean() {
				all_hour_mean(this.company, this.branch_content, this.branch_level).then(res => {
					console.log("===allHourMean===");
					console.log(res);
					if (res.status != 200) {
						return this.$message.error('获取日心情指数失败')
					}
					this.emotionAllHour = res.data.emotionAllHour;
					this.stressAllHour = res.data.stressAllHour;
					this.tiredAllHour = res.data.tiredAllHour;
					let emotionArr = [this.emotionAllHour, this.tiredAllHour, this.stressAllHour];
					console.log(emotionArr);
					let emotionStr = ['情绪', '疲劳', '压力'];
					let i = 0;
					setInterval(() => {
						this.echartTitle = emotionStr[i];
						this.drawmap(emotionArr[i], emotionStr[i])
						i += 1;
						if (i > 2) {
							i = 0;
						}
					}, 5000)

				})
			},
			//获取概览数据
			hourIndex() {
				hour_index(this.company, this.branch_content, this.branch_level).then(res => {
					console.log("=====hourIndex=");
					console.log(res);
					if (res.status == 400) {
						return this.$message.error('暂无数据')
					}
					if (res.status != 200) {
						return this.$message.error('获取概览失败')
					}
					for (let i in res.data.hourIndex) {

						if (this.userData[this.userData_page].length == this.page_count) {
							this.userData.push([])
							this.userData_page += 1;
						}
						this.userData[this.userData_page].push(res.data.hourIndex[i]);
						res.data.hourIndex[i].marker_show = false;
						res.data.hourIndex[i].eventClick = {
							click: (e) => {
								this.userInfo = res.data.hourIndex[i];
								this.infoLocation = [res.data.hourIndex[i].Lon, res.data.hourIndex[i].Lat]
								this.showInfo = true;
							}
						}
						if (res.data.hourIndex[i].emotion_mean < 50) {
							if (this.emotion_wram[this.emotion_wram_page].length == this.page_count) {
								this.emotion_wram.push([])
								this.emotion_wram_page += 1;
							}
							this.emotion_wram[this.emotion_wram_page].push(res.data.hourIndex[i])
						}

						if (res.data.hourIndex[i].tired_mean > 69) {
							if (this.tired_wram[this.tired_wram_page].length == this.page_count) {
								this.tired_wram.push([])
								this.tired_wram_page += 1;
							}
							this.tired_wram[this.tired_wram_page].push(res.data.hourIndex[i])
						}
						if (res.data.hourIndex[i].stress_mean > 69) {
							if (this.stress_wram[this.stress_wram_page].length == this.page_count) {
								this.stress_wram.push([])
								this.stress_wram_page += 1;
							}
							this.stress_wram[this.stress_wram_page].push(res.data.hourIndex[i])
						}
						this.user_point.push(res.data.hourIndex[i])
					}
					this.infoLocation = [res.data.hourIndex[0].Lon, res.data.hourIndex[0].Lat]
				})
			},
			//获取总体指数
			overAll() {
				overall(this.company, this.branch_content, this.branch_level).then(res => {
					console.log("===overall===s");
					console.log(res);
					// return
					if (res.status == 400) {
						return this.$message.error('暂无数据')
					}
					if (res.status != 200) {
						return this.$message.error('获取失败')
					}
					this.allNum = res.data.allNum;
					this.allEmotion = res.data.emotion_mean;
					this.allStress = res.data.stress_mean;
					this.allTired = res.data.tired_mean;
					this.allEmotionPre = res.data.pre_emotion_mean;
					this.allStressPre = res.data.pre_stress_mean;
					this.allTiredPre = res.data.pre_tired_mean;
					//对比昨天
					if (this.allEmotion >= this.allEmotionPre) {
						let dif = (this.allEmotion / this.allEmotionPre - 1) * 100
						this.contrastEmotion = dif.toFixed(1)
					}
					if (this.allEmotion < this.allEmotionPre) {
						let dif = (1 - this.allEmotion / this.allEmotionPre) * 100
						this.contrastEmotion = dif.toFixed(1)
					}
					if (this.allStress >= this.allStressPre) {
						let dif = (this.allStress / this.allStressPre - 1) * 100
						this.contrastStress = dif.toFixed(1)
					}
					if (this.allStress < this.allStressPre) {
						let dif = (1 - this.allStress / this.allStressPre) * 100
						this.contrastStress = dif.toFixed(1)
					}
					if (this.allTired >= this.allTiredPre) {
						let dif = (this.allTired / this.allTiredPre - 1) * 100
						this.contrastTired = dif.toFixed(1)
					}
					if (this.allTired < this.allTiredPre) {
						let dif = (1 - this.allTired / this.allTiredPre) * 100
						this.contrastTired = dif.toFixed(1)
					}
					//指数颜色和图标
					if (this.allEmotion >= 0 && this.allEmotion <= 49) {
						this.emotionColor = {
							color: '#FF0036'
						}
						this.emotionIcn = 1
					}
					if (this.allEmotion >= 50 && this.allEmotion <= 69) {
						this.emotionColor = {
							color: '#01D6D6'
						}
						this.emotionIcn = 2
					}
					if (this.allEmotion >= 70 && this.allEmotion <= 100) {
						this.emotionColor = {
							color: '#55CF04'
						}
						this.emotionIcn = 3
					}
					if (this.allTired >= 0 && this.allTired <= 49) {
						this.tiredColor = {
							color: '#55CF04'
						}
						this.tiredIcn = 1
					}
					if (this.allTired >= 50 && this.allTired <= 69) {
						this.tiredColor = {
							color: '#01D6D6'
						}
						this.tiredIcn = 2
					}
					if (this.allTired >= 70 && this.allTired <= 100) {
						this.tiredColor = {
							color: '#FF0036'
						}
						this.tiredIcn = 3
					}
					if (this.allStress >= 0 && this.allStress <= 49) {
						this.stressColor = {
							color: '#FF0036',
						}
						this.stressIcn = 1
					}
					if (this.allStress >= 50 && this.allStress <= 69) {
						this.stressColor = {
							color: '#01D6D6'
						}
						this.stressIcn = 2
					}
					if (this.allStress >= 70 && this.allStress <= 100) {
						this.stressColor = {
							color: '#55CF04'
						}
						this.stressIcn = 3
					}
					// setTimeout(() => {
					// 	this.warmPlay()
					// }, 1200)
				})
			},
			equipmentList() {
				let branchs = this.branch_content.split('|')
				
				equipment_list(this.company, '', branchs[0]||'',branchs[1]||'', 1, 1000).then(res => {
					// console.log('equipmentList');
					// console.log(res);
					if (res.status != 200) {
						return this.$message.error('获取人员列表失败')
					}
					for (let i of res.data.equipmentData) {
						if (i.is_online == 'online') {
							this.onLine += 1;
						}
					}
				})
			},
			bigdata() {
				sessionStorage.setItem('activePath','/index')
				this.$router.replace({
					path: '/index',
					query: {
						path: '/index',
					},
				})
			},
			//开启警报声音
			warmPlay() {
				if (this.tired_wram[0].length > 0 || this.stress_wram[0].length > 0 || this.emotion_wram[0]
					.length > 0) {
					console.log("开启警报声音");
					document.getElementById('warmPalyer').play()
				}
			},
			drawmap(data, title) {
				// 基于准备好的dom，初始化echarts实例
				let initData = [];
				for (let i in data) {
					if (i < 8) continue;
					if (data[i]) {
						initData.push(data[i])
					} else {
						initData.push(null)
					}
				}
				let myChart = echarts.init(document.getElementById('main'));
				myChart.setOption(echartsOption(initData, title));
				window.onresize = function() {
					myChart.resize()
				}
			},
		},
		created() {
			let branch_content = sessionStorage.getItem('branch-content');
			if(branch_content && branch_content!='null'){
				this.branch_content = branch_content
			}else{
				this.branch_content = ''
			}
			this.company = sessionStorage.getItem('company');
			this.branch_level = sessionStorage.getItem('level');
		},
		async mounted() {
			this.allHourMean()
			this.overAll();
			// this.hourWarning()
			this.hourIndex()
			this.equipmentList()
			setInterval(() => {
				let date = new Date();
				this.dateTime =
					`${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${date.getHours()}时${date.getMinutes()}分${date.getSeconds()}秒`
			}, 1000)
		}
	};
</script>
<style lang='less' scoped>
	.container {
		height: 100vh;
		background-color: #121c2b;
	}

	.header {
		height: 8vh;
		display: flex;
		justify-content: space-between;
	}

	.time {
		height: 8vh;
		line-height: 8vh;
		font-size: 0.2rem;
		color: #fff;
		width: 25%;
		text-align: center;
	}

	.page-title {
		height: 8vh;
		width: 50%;
		position: relative;
	}

	.header-text {
		height: 8vh;
		line-height: 8vh;
		font-size: 0.3rem;
		text-align: center;
		color: #fff;
		text-shadow: 0 0 .3rem #00d8ff;
	}

	.title-bg {
		background: url('../assets/images/head.gif') no-repeat center center;
		background-size: 100%;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 4vh;
	}

	.header-r {
		width: 25%;
		height: 8vh;
		display: flex;
		justify-content: space-around;
	}

	.online {
		height: 8vh;
		line-height: 8vh;
		font-size: 00.2rem;
		color: #fff;
	}

	.overview {
		height: 4vh;
		line-height: 4vh;
		text-align: center;
		background-color: #37a9ea;
		font-size: 0.2rem;
		color: #fff;
		margin-top: 2vh;
		padding: 0px 10px;
		border-radius: 5px;
		cursor: pointer;
	}

	.header-b {
		width: 95%;
		margin: 1vh auto;
		height: 2vh;
		background: url('../assets/images/header.png') no-repeat;
		background-size: 100%;
	}

	.main {
		width: 95%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.main-left {
		width: 24%;
	}

	.main-mid {
		width: 50%;
	}

	.main-right {
		width: 24%;
	}

	.emotion-warning {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 28vh;
	}

	.modal-a {
		position: absolute;
		width: 80%;
		height: 100%;
		bottom: -1px;
		top: -1px;
		left: 10%;
		border-bottom: 1px solid #007297;
		border-top: 1px solid #007297;
		transition: all 0.5s;
	}

	.modal-b {
		position: absolute;
		width: 100%;
		height: 80%;
		left: -1px;
		right: -1px;
		top: 10%;
		border-left: 1px solid #007297;
		border-right: 1px solid #007297;
		transition: all 0.5s;
	}

	.main-center {
		color: #fff;
		top: 3vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.bor:hover .modal-a {
		width: 0px;
	}

	/* 	.bor:hover .modal-b {
		height: 0px;
	} */

	.module-title {
		height: 3vh;
		display: flex;
		justify-content: flex-start;
	}

	.title-icn {
		position: absolute;
		;
		height: 2vh;
		width: 3px;
		margin-top: 0.5vh;
		margin-left: 10px;
		background-color: #59ebe8;
	}

	.title-text {
		height: 3vh;
		line-height: 3vh;
		font-size: 0.25rem;
		color: #fff;
		/* margin-left: 5px; */
		width: 100%;
		text-align: center;
		font-weight: bold;
		border: 1px solid #00d8ff;
	}

	.tr-title td {
		border: 1px solid #00d8ff;
		/* padding: 1px; */
	}

	.tr-title {
		padding: 4px;
		border: 1px solid #00d8ff;
		color: #007297;
	}

	.tired-warning {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 27vh;
		margin-top: 1vh;
	}

	.stress-warning {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 27vh;
		margin-top: 1vh;
	}

	.map {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 38vh;
	}

	.index {
		height: 17vh;
		display: flex;
		justify-content: space-between;
		margin-top: 1vh;
	}

	.index-item {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 17vh;
		width: 32%;
	}

	.tired-trend {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 27vh;
		margin-top: 1vh;
	}

	.emotion-overview {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 28vh;
	}

	.tired-overview {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 27vh;
		margin-top: 1vh;
	}

	.stress-overview {
		border: 1px solid #0bc4e9;
		position: relative;
		height: 27vh;
		margin-top: 1vh;
	}

	.box1 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	.box1 img {
		width: .68rem;
		height: .68rem;
	}

	.box1 .rise-icon {
		width: .46rem;
		height: .46rem;
	}

	.box1 .left-t {
		font-size: .26rem;
		color: white;
	}

	.box1 .left-num {
		margin: 0 .1rem;
		font-size: .56rem;
		font-weight: bolder;
	}

	.box1 .rise-num,
	.left-t,
	.rise-t {
		font-size: .2rem;
	}

	.left-bolck-content {
		margin-top: 5px;
	}

	.left-bolck-content .tr-title {
		font-size: .22rem;
	}

	.left-bolck-content .tr-body {
		font-size: .2rem;
	}

	.statusDot {
		width: .2rem;
		height: .2rem;
		display: inline-block;
		border-radius: 50%;
		position: relative;
		left: .05rem;
		top: .03rem;
		background-color: #25cc00;
	}

	.warmDot {
		background-color: yellow;
	}

	.infoDot {
		background-color: #aaff00;
	}

	.dangerDot {
		background-color: red;
	}

	.bm-view {
		width: 100%;
		height: 38vh !important;
	}

	.warmBorder {
		/* border: #00d8ff; */
		border-color: red !important;
		/* border: 2px solid red  !important; */
		box-sizing: border-box;
		animation: warmborder 2s infinite linear;
	}
	.bor,.index-item{
		background-color: rgb(35, 64, 114);
	}
	.borderBox {
		animation: boderBox 2s infinite linear;
	}

	.boderLF {
		animation: boderLF 2s infinite linear;
	}

	.boderTB {
		animation: boderTB 2s infinite linear;
	}

	.warmbgcolor {
		animation: warmbgcolor 2s infinite linear;
	}

	@keyframes warmborder {
		0% {
			border: 1px solid red;
			border-color: #00d8ff;
		}

		100% {
			border-color: red;
		}
	}

	@keyframes boderBox {
		0% {
			border: 1px solid #0bc4e9;
		}

		100% {
			border: 1px solid red;
		}
	}

	@keyframes boderLF {
		0% {
			border-left: 1px solid #007297;
			border-right: 1px solid #007297;
		}

		100% {
			border-left: 1px solid red;
			border-right: 1px solid red;
		}
	}

	@keyframes boderTB {
		0% {
			border-top: 1px solid #007297;
			border-bottom: 1px solid #007297;
		}

		100% {
			border-top: 1px solid red;
			border-bottom: 1px solid red;
		}
	}

	@keyframes warmbgcolor {
		0% {
			background-color: #59ebe8;
		}

		100% {
			background-color: red;
		}
	}
</style>
