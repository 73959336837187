import * as echarts from "echarts"
export function echartsOption(data) {
	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow',
				shadowStyle: {
					color: 'rgba(14,34,68,.5)',
					shadowColor: 'rgba(255, 0, 59, 0.1)',
					shadowBlur: 5
				}
			},
			formatter: '今天 {b}<br />疲劳值 {c}'
		},
		legend: {
			left: '0',
			align: 'left',
			top: '0',
			textStyle: {
				color: '#fff'
			},
		},
		grid: {
			top: '20%',
			left: '0',
			right: '3%',
			bottom: '5%',
			containLabel: true
		},
		xAxis: [{
			splitNumber: 6,
			type: 'category',
			axisLine: {
				show: true,
				lineStyle: {
					type: 'solid',
					color: '#1aaed7',
					width: '2'
				}
			},
			axisTick: {
				"show": true
			},
			axisLabel: {
				color: '#1aaed7',
				width: 100
			},
			splitLine: {
				show: false
			},
			boundaryGap: false,
			data: ["8:00","9:00","10:00","11:00", "12:00","13:00","14:00","15:00", "16:00", "17:00","18:00","19:00","20:00","21:00","22:00"]

		}],

		yAxis: [{
			type: 'value',
			min: 0,
			max: 100,
			splitNumber: 5,
			splitLine: {
				show: true,
				lineStyle: {
					color: '#3482ff'
				},
			},
			axisLine: {
				show: false,
			},
			axisLabel: {
				show: true,
				margin: 20,
				textStyle: {
					color: '#1aaed7',

				},
			},
			axisTick: {
				show: false,
			},
		}],
		series: [
			// {
			// 	name: '本周',
			// 	type: 'line',
			// 	showAllSymbol: true,
			// 	symbol: 'circle',
			// 	symbolSize: 10,
			// 	lineStyle: {
			// 		normal: {
			// 			color: "#A582EA",
			// 		},
			// 	},
			// 	label: {
			// 		show: false
			// 	},
			// 	itemStyle: {
			// 		color: "rgba(89,47,180,1)",
			// 		borderColor: "rgba(89,47,180,.3)",
			// 		borderWidth: 8
			// 	},
			// 	areaStyle: {
			// 		normal: {
			// 			color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
			// 					offset: 0,
			// 					color: 'rgba(89,47,180,0.8)'
			// 				},
			// 				{
			// 					offset: 1,
			// 					color: 'rgba(89,47,180,0)'
			// 				}
			// 			], false),
			// 		}
			// 	},
			// 	data: [ 30, 60, 70, 50,40, 30, 60, 70, 50,40, 30, 60, 70, 50,40, 30, 60, 70]
			// },
			{
				name: '今天',
				type: 'line',
				showAllSymbol: true,
				symbol: 'circle',
				symbolSize: 10,
				lineStyle: {
					normal: {
						color: "#2CABE3",
					},
				},
				label: {
					show: true
				},
				itemStyle: {
					color: "rgba(53,108,255,1)",
					borderColor: "rgba(53,108,255,.4)",
					borderWidth: 8
				},
				areaStyle: {
					normal: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: 'rgba(43,58,155,0.8)'
							},
							{
								offset: 1,
								color: 'rgba(43,58,155,0.3)'
							}
						], false),
					}
				},
				data
			},
		]
	};
}
